import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthService } from '../../services';

@Injectable({
  providedIn: 'root',
})
export class AuthLoggedGuard implements CanActivate {
  constructor(
    private _authService: AuthService,
    private router: Router
  ) {}
  canActivate(): Observable<boolean> | boolean {
    return this._authService.loggedIn.pipe(
      take(1),
      map((isLogged: boolean) => {
        if (!isLogged) {
          this.router.navigate(['/panel']);

          return false;
        }

        return true;
      })
    );
  }
}
