import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../base/base.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TableService extends BaseService {
  private urlSite = environment.url_site;
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  async copyLink(documentId: any): Promise<string> {
    const URI = `${this.urlSite}form/${documentId}`;

    return URI;
  }
}
