import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { AUROFAST_CONSTANTS } from '../../models/constants';
import { jwtDecode } from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  httpOptions: any = {
    headers: new HttpHeaders({
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
    }),
  };
  private urlApi = environment.url_api;

  constructor(private http: HttpClient) {}

  createList(data: any, typeVisibility: string): Observable<any> {
    const dataUser = JSON.parse(`${localStorage.getItem('dataUser')}`);
    const infoToken: any = jwtDecode(dataUser?.access_token);
    const shared_with = {
      mode: typeVisibility,
      list: [],
    };

    if (infoToken?.user.company) {
      data.append('company', infoToken?.user.company);
    }
    data.append('owner', dataUser?.user_id);
    data.append('shared_with', JSON.stringify(shared_with));

    const URI: string = this.urlApi + 'config-list/upload-csv';

    return this.http.post(URI, data);
  }

  updateList(id: string, data: any): Observable<any> {
    try {
      const URI: string = this.urlApi + `config-list/${id}`;

      return this.http.put(URI, data);
    } catch (error) {
      const response: any = {
        error: true,
        msg: error,
      };

      return response;
    }
  }

  getLists(): Observable<any> {
    const dataUser: {
      access_token: string;
      error: boolean;
      full_name: string;
      menu: any;
      refresh_token: string;
      user_id: string;
    } = JSON.parse(`${localStorage.getItem('dataUser')}`);
    let infoToken: any;

    if (dataUser?.access_token && typeof dataUser.access_token === 'string') {
      infoToken = jwtDecode(dataUser.access_token);
      if (infoToken.user.company) {
        const URI: string =
          this.urlApi +
          `config-list/owner/${dataUser.user_id}/company/${infoToken.user.company}/status/${AUROFAST_CONSTANTS.AURODOC_STATUS_ACTIVE}`;

        return this.http.get(URI, this.httpOptions);
      } else {
        const URI: string =
          this.urlApi + `config-list/owner/${dataUser?.user_id}/status/${AUROFAST_CONSTANTS.AURODOC_STATUS_ACTIVE}`;

        return this.http.get(URI, this.httpOptions);
      }
    }

    return of(null);
  }

  getList(id: string): Observable<any> {
    const URI: string = this.urlApi + `config-list/${id}`;

    return this.http.get(URI, this.httpOptions);
  }

  deleteList(id: string): Observable<any> {
    const URI: string = this.urlApi + `config-list/${id}`;

    return this.http.delete(URI);
  }
}
