import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { jwtDecode } from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class AuthUserAdminGuard implements CanActivate {
  infoToken: any;

  constructor(private router: Router) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const localData = JSON.parse(`${localStorage.getItem('dataUser')}`);
    this.infoToken = jwtDecode(localData.access_token);

    if (this.infoToken.user.rol == 'ADMIN' || this.infoToken.user.rol == 'ADMIN_COMPANY') {
      return true;
    }
    this.router.navigate(['/']);

    return false;
  }
}
