import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DocumentService } from '../../services';

@Injectable({
  providedIn: 'root',
})
export class WorkspaceService {
  constructor(
    private router: Router,
    private documentService: DocumentService
  ) {}

  setURL(id: string) {
    this.router.navigate(['/panel/workspace', id]);
  }

  updateDocumentStatus(id: string, status: string) {
    const statusDoc = {
      status_doc: status,
    };
    this.documentService.updateDocument(statusDoc, id).subscribe(value => {
      if (!value.error) {
        this.router.navigate(['/panel/workspace', id]);
      }
    });
  }
}
