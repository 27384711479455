import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BaseService } from '../../services';
import { WebApiResponse } from '../../models/types';

@Injectable({
  providedIn: 'root',
})
export class LayoutService extends BaseService {
  private urlApi = environment.url_api + 'frame';

  constructor(http: HttpClient) {
    super(http);
  }

  getLayoutsByOwner(idOwner: string): WebApiResponse<any> {
    const URI = 'frame/owner/' + idOwner + '/status/ACTIVE';

    return this.get(URI);
  }

  uploadImage(formData: FormData, idLayout: string, fileS3: string) {
    const URI = 'frame/' + idLayout + '/upload/image/' + fileS3;

    return this.post(URI, formData);
  }

  updateLayout(idLayout: string, body: any): Observable<any> {
    const URI = 'frame/' + idLayout;

    return this.put(URI, body);
  }

  createLayout(body: any): Observable<any> {
    const URI = 'frame';

    return this.post(URI, body);
  }

  deleteLayout(idLayout: string): Observable<any> {
    const URI = 'frame/' + idLayout;

    return this.delete(URI);
  }

  getLayoutById(idLayout: string): Observable<any> {
    const URI = 'frame/' + idLayout;

    return this.get(URI);
  }

  getLayoutsByStatusAndType(type: string) {
    const idOwner = JSON.parse(localStorage.getItem('dataUser') || '').user_id;
    const URI = 'frame/owner/' + idOwner + '/type_frame/' + type + '/status/ACTIVE';

    return this.get(URI);
  }
}
