import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AUROFAST_CONSTANTS } from '@app/core/models/constants';

@Injectable({
  providedIn: 'root',
})
export class RecoveryPasswordService {
  urlApi = environment.url_api;
  constructor(private http: HttpClient) {}

  async forgotPassword(datauser: any): Promise<any> {
    try {
      const URI = this.urlApi + `auth/forgot-password/${AUROFAST_CONSTANTS.AUROFAST_FORGOT_PASSWORD}`;

      const data = await this.http.post(URI, datauser).toPromise();

      return data;
    } catch (error) {
      return error;
    }
  }

  async resetPassword(datauser: any, token: any): Promise<any> {
    try {
      const URI = this.urlApi + `auth/reset-password/${token}`;

      const data = await this.http.post(URI, datauser).toPromise();

      return data;
    } catch (error) {
      return error;
    }
  }
}
