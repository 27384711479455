import { Injectable } from '@angular/core';
import { DataForm, GridSettings } from '../../models/interfaces';
import { KtdGridLayoutItem } from '@katoid/angular-grid-layout';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  NUMBER_COLUMNS,
  NUMBER_ROW_HEIGHT,
  NUMBER_BORAD_WIDTH,
  USED_COLORS,
  INPUTS_GROUPS,
} from '../../models/constants';
@Injectable({
  providedIn: 'root',
})
export class GridService {
  /** layout grid settings */
  private layout: GridSettings[] = [];

  /** Used colors array */
  private colors: string[] = [];

  /** Used colors array */
  private groups: string[] = [];

  /** Form owner */
  private formOwner = '';

  /** Form code */
  private formCode = '';

  /** Subject to listen changes in layout */
  private layoutChanged$ = new BehaviorSubject<GridSettings[]>(this.layout);

  /** Subject to listen changes in grid columns number */
  private columnsGrid$ = new BehaviorSubject<number>(NUMBER_COLUMNS);

  /** layout grid settings */
  private rulerCols$ = new BehaviorSubject<any[]>(Array.from({ length: NUMBER_COLUMNS }));

  /** Subject to listen changes in grid row height number */
  private rowHeightGrid$ = new BehaviorSubject<number>(NUMBER_ROW_HEIGHT);

  /** Subject to listen changes in board width number */
  private boardWidth$ = new BehaviorSubject<number>(NUMBER_BORAD_WIDTH);

  /** Subject to listen edit grid event is triggered */
  private gridToEdit$ = new BehaviorSubject<GridSettings | null>(null);

  /** Subject to listen used colors grid event is triggered */
  private usedColors$ = new BehaviorSubject<string[]>(USED_COLORS);

  /** Subject to listen inputs groups event is triggered */
  private inputsGroups$ = new BehaviorSubject<string[]>(INPUTS_GROUPS);

  /**
   * get number columns
   */
  get columnsNumber(): Observable<number> {
    return this.columnsGrid$.asObservable();
  }

  /**
   * get number row height
   */
  get rowHeight(): Observable<number> {
    return this.rowHeightGrid$.asObservable();
  }

  /**
   * get number row height
   */
  get boardWidth(): Observable<number> {
    return this.boardWidth$.asObservable();
  }

  /**
   * get layout grid settings
   * @returns grid settings observable
   */
  get layoutGrid(): Observable<GridSettings[]> {
    return this.layoutChanged$.asObservable();
  }

  /**
   * get grid to edit
   */
  get girdToEdit(): Observable<GridSettings | null> {
    return this.gridToEdit$.asObservable();
  }

  /**
   * get ruler columns
   */
  get rulerCols(): Observable<number[]> {
    return this.rulerCols$.asObservable();
  }

  /**
   * get used colors
   */
  get usedColors(): Observable<string[]> {
    return this.usedColors$.asObservable();
  }

  /**
   * get used colors
   */
  get inputsGroups(): Observable<string[]> {
    return this.inputsGroups$.asObservable();
  }

  /**
   * set form code
   * @param {string} code
   */
  setFormCode(code: string): void {
    this.formCode = code;
  }

  /**
   * set form owner
   * @param {string} owner
   */
  setFormOwner(owner: string): void {
    this.formOwner = owner;
  }

  /**
   * get form code
   */
  getFormCode(): string {
    return this.formCode;
  }

  /**
   * get form owner
   */
  getFormOwner(): string {
    return this.formOwner;
  }

  /**
   * change number columns
   * @param {number} numberCols
   */
  changeColumns(numberCols: number): void {
    this.columnsGrid$.next(numberCols);
    this.rulerCols$.next(Array.from({ length: numberCols }));
  }

  /**
   * change number row height
   * @param {number} rowHeight
   */
  changeRowHeight(rowHeight: number): void {
    this.rowHeightGrid$.next(rowHeight);
  }

  /**
   * change number board width
   * @param {number} boardWidth
   */
  changeBoardWidth(boardWidth: number): void {
    this.boardWidth$.next(boardWidth);
  }

  /**
   * Add used colors
   * @param {string} color
   */
  addUsedColors(color: string): void {
    this.colors.push(color);
    this.usedColors$.next(this.colors);
  }

  /**
   * Update array colors
   * @param {string[]} colors
   */
  updateUsedColors(colors: string[]): void {
    this.colors = colors;
    this.usedColors$.next(this.colors);
  }

  /**
   * Add input group
   * @param {string} group
   */
  addInputsGroups(group: string): void {
    this.groups.push(group);
    this.inputsGroups$.next(this.groups);
  }

  /**
   * Update array groups
   * @param {string[]} groups
   */
  updateInputsGroups(groups: string[]): void {
    this.groups = groups;
    this.inputsGroups$.next(this.groups);
  }

  /**
   * reset layout grid settings
   */
  resetLayout(): void {
    this.layout = [];
    this.layoutChanged$.next(this.layout);
  }

  addGroupGrids(grids: GridSettings[]): void {
    this.layout = [...grids];
    this.layoutChanged$.next(this.layout);
  }
  /**
   * add grid to layout
   * @param {DataForm} dataForm
   */
  addGrid(data: { content: string }): void {
    const maxId = this.layout.reduce((acc, cur) => Math.max(acc, parseInt(cur.id, 10)), -1);
    const nextId = maxId + 1;
    const yGrids: number[] = [];
    this.layout.forEach(item => yGrids.push(item.y));
    const newGrid: GridSettings = {
      ...data,
      id: nextId.toString(),
      x: 0,
      y: yGrids.length > 0 ? Math.max(...yGrids) : 0,
      w: 2,
      h: 2,
    };
    if (newGrid.y > 3) {
      return;
    }
    this.layout = [...this.layout, newGrid];
    this.layoutChanged$.next(this.layout);
  }

  resetGrid(): void {
    this.layout = [];
    this.layoutChanged$.next(this.layout);
  }

  /**
   * clone grid to layout
   * @param {GridSettings} grid
   */
  cloneGrid(grid: GridSettings): void {
    const maxId = this.layout.reduce((acc, cur) => Math.max(acc, parseInt(cur.id, 10)), -1);
    const nextId = maxId + 1;
    // const yGrids: number[] = [];
    // this.layout.forEach(item => yGrids.push(item.y));
    const newGrid: GridSettings = {
      ...grid,
      id: nextId.toString(),
      // y: yGrids.length > 0 ? Math.max(...yGrids) : 0,
      y: grid.y + 1,
    };
    this.layout = [...this.layout, newGrid];
    this.layoutChanged$.next(this.layout);
  }

  /**
   * On layoud grid settings change
   * @param {KtdGridLayoutItem[]} layoutSettings
   */
  layoutUpdated(layoutSettings: KtdGridLayoutItem[]): void {
    const auxLayout: GridSettings[] = [];
    this.layout.forEach((item: GridSettings, index: number) => {
      auxLayout.push({
        ...item,
        id: layoutSettings[index].id,
        x: layoutSettings[index].x,
        y: layoutSettings[index].y,
        w: layoutSettings[index].w,
        h: layoutSettings[index].h,
      });
    });

    //crea una funcion que recorra auxlayout por cada fila si en orden progresivo las posiciones x tienen una diferencia mayor a 1, se debe cambiar la x al numero que sigue en orden ejemplo: [x:3], [x:6] el [x6] pasa a ser [x:4]

    this.layout = [...auxLayout];
    this.layoutChanged$.next(this.layout);
  }

  /**
   * set form to edit grid layout
   * @param {GridSettings} editedGrid
   */
  setGridToEdit(gridToEdit: GridSettings | null): void {
    this.gridToEdit$.next(gridToEdit);
  }

  /**
   * edit grid from layout
   * @param {GridSettings} editedGrid
   * @param {DataForm} dataForm
   */
  editGrid(editedGrid: GridSettings, dataForm: DataForm): void {
    const editedData: GridSettings = {
      ...editedGrid,
      ...dataForm,
    };
    const editedGridIndex = this.layout.findIndex(item => item.id === editedGrid?.id);
    if (editedGridIndex >= 0) {
      this.layout[editedGridIndex] = { ...editedData };
      this.layoutChanged$.next(this.layout);
    }
  }

  /**
   * remove grid form layout
   * @param {GridSettings[]} layout
   * @param {Function} condition
   * @returns array of grid settings
   */
  layoutRemoveGrid(layout: GridSettings[], condition: (item: GridSettings) => boolean): GridSettings[] {
    const arrayCopy: GridSettings[] = [...layout];
    const index = layout.findIndex(item => condition(item));
    if (index > -1) {
      arrayCopy.splice(index, 1);
    }

    return arrayCopy;
  }

  /**
   * remove grid settings from layout
   * @param {string[]} id
   */
  removeGrid(id: string): void {
    this.layout = this.layoutRemoveGrid(this.layout, item => item.id === id);
    this.layoutChanged$.next(this.layout);
    this.gridToEdit$.next(null);
  }

  /**
   * unsubscribe of this service
   */
  unsubscribeLayoutService(): void {
    this.layoutChanged$.unsubscribe();
  }

  async getAllLocalStorage() {
    const items: any = {};
    const keys: string[] = Object.keys(localStorage);
    for await (const item of keys) {
      items[item] = localStorage.getItem(item) ?? '';
    }

    return items;
  }
}
