import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SseService {
  constructor() {}

  getEventSource(url: string, data: any): EventSource {
    return new EventSource(url, data);
  }
}
