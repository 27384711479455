import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  itemLink: any;

  constructor(private router: Router) {}

  toPage(page: string) {
    document.getElementById(page)?.scrollIntoView();

    if (page != 'home') {
      if (page == 'about') {
        this.router.navigate(['/home']);

        return (this.itemLink = 'about-active');
      } else if (page == 'contact') {
        this.router.navigate(['/home']);

        return (this.itemLink = 'contact-active');
      } else if (page == 'login') {
        return (this.itemLink = 'login-active');
      } else {
        return (this.itemLink = 'register-active');
      }
    } else {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });

      return (this.itemLink = 'home-active');
    }
  }
}
