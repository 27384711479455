import { HttpClient } from '@angular/common/http';
import { ServiceResult, WebApiResponse } from '../../models/types';
import { environment } from 'src/environments/environment';

export abstract class BaseService {
  // protected endpointBase = '';
  private apiUrl = environment.url_api;

  constructor(private httpClient: HttpClient) {}

  protected get<T>(endpoint: string): WebApiResponse<T> {
    return this.httpClient.get<ServiceResult<T>>(`${this.apiUrl}${endpoint}`);
  }

  protected post<T, U>(endpoint: string, body: U): WebApiResponse<T> {
    return this.httpClient.post<ServiceResult<T>>(`${this.apiUrl}${endpoint}`, body);
  }

  protected put<T, U>(endpoint: string, body: U): WebApiResponse<T> {
    return this.httpClient.put<ServiceResult<T>>(`${this.apiUrl}${endpoint}`, body);
  }

  protected delete<T>(endpoint: string): WebApiResponse<T> {
    return this.httpClient.delete<ServiceResult<T>>(`${this.apiUrl}${endpoint}`);
  }

  protected getFullURL(endpoint: string): string {
    return `${this.apiUrl}${endpoint}`;
  }
}
