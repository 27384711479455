import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NewFileService {
  base64!: string;

  componentMethodCallSource = new Subject<any>();
  componentMethodCalled$ = this.componentMethodCallSource.asObservable();

  changeFile(file: Blob) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e: any) => {
      const baseString = JSON.stringify(e.target.result);
      this.base64 = baseString;
      this.componentMethodCallSource.next(this.base64);
      // this.componentMethodCallSource.complete();
    };
    reader.onerror = () => {
      this.componentMethodCallSource.error('Error leyendo el archivo');
    };
  }

  loadDocumentDOM(document: any): any {
    return new Observable<any>(observer => {
      if (document) {
        observer.next(document);
        observer.complete();
      }
    });
  }
}
