/* eslint-disable object-property-newline */
import { TypeGrid, AlignTextInput, AlignVerticalContent } from '../enums/grid-settings.enum';

export const TYPELISTOPTIONS = [
  { label: 'Texto', value: TypeGrid.TEXT },
  { label: 'Input', value: TypeGrid.INPUT },
  { label: 'Imagen', value: TypeGrid.IMAGE },
];

export const TYPEVERTICALALIGN = [
  { label: 'Arriba', value: AlignVerticalContent.START },
  { label: 'Centrado', value: AlignVerticalContent.CENTER },
  { label: 'Abajo', value: AlignVerticalContent.END },
];

export const TYPEHORIZONTALALIGN = [
  { label: 'Izquierda', value: AlignTextInput.LEFT },
  { label: 'Centrado', value: AlignTextInput.CENTER },
  { label: 'Derecha', value: AlignTextInput.RIGHT },
];

export const TYPEHORIZONTALALIGNINPUT = [
  { label: 'Izquierda', value: AlignTextInput.LEFT },
  { label: 'Centrado', value: AlignTextInput.CENTER },
  { label: 'Derecha', value: AlignTextInput.RIGHT },
];

export const FONTSIZES = [
  { label: '10px', value: '10px' },
  { label: '12px', value: '12px' },
  { label: '14px', value: '14px' },
  { label: '16px', value: '16px' },
  { label: '18px', value: '18px' },
  { label: '20px', value: '20px' },
  { label: '22px', value: '22px' },
  { label: '24px', value: '24px' },
  { label: '26px', value: '26px' },
  { label: '28px', value: '28px' },
  { label: '30px', value: '30px' },
  { label: '32px', value: '32px' },
  { label: '34px', value: '34px' },
  { label: '36px', value: '36px' },
  { label: '38px', value: '38px' },
  { label: '40px', value: '40px' },
];

export const FONTBOLD = [
  { label: 'Normal', value: 'normal' },
  { label: 'Negrita', value: 'bold' },
];

export const LAYOUT_GRID_SETTINGS = [];
export const NUMBER_COLUMNS = 20;
export const NUMBER_ROW_HEIGHT = 20;
export const NUMBER_BORAD_WIDTH = 1000;
export const USED_COLORS = [];
export const INPUTS_GROUPS = [];

export const LAYOUT_GRID_SETTINGS_KEY = 'LAYOUT_GRID_SETTINGS';
export const NUMBER_COLUMNS_KEY = 'NUMBER_COLUMNS';
export const NUMBER_ROW_HEIGHT_KEY = 'NUMBER_ROW_HEIGHT';
export const NUMBER_BORAD_WIDTH_KEY = 'NUMBER_BORAD_WIDTH';
export const USED_COLORS_KEY = 'USED_COLORS';
export const INPUTS_GROUPS_KEY = 'INPUTS_GROUPS';
