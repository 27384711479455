import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  dataUser = JSON.parse(`${localStorage.getItem('dataUser')}`);
  avatarImg = localStorage.getItem('imgUser') ?? 'assets/img/avatar-default.svg';

  httpOptions: any = {
    headers: new HttpHeaders({
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
    }),
  };

  /** Subject to listen changes in full name user */
  private fullNameSubject$ = new BehaviorSubject<string>(this.dataUser?.full_name ?? '');
  /** Subject to listen changes in avatar user */
  private avatarUserSubject$ = new BehaviorSubject<string>(this.avatarImg ?? '');
  private urlApi = environment.url_api;

  constructor(private http: HttpClient) {}

  getUserById(id: string): Observable<any> {
    const URI: string = this.urlApi + `user/${id}`;

    return this.http.get(URI, this.httpOptions);
  }

  updateProfileUser(data: any): Observable<any> {
    const URI: string = this.urlApi + `user/${this.dataUser?.user_id}`;

    return this.http.put(URI, data);
  }

  changePassword(data: any): Observable<any> {
    const URI: string = this.urlApi + `user/change-password/${this.dataUser?.user_id}`;

    return this.http.put(URI, data);
  }

  updatePhotoUser(dataUser: any): Observable<any> {
    const URI = this.urlApi + `user/avatar/${this.dataUser?.user_id}`;
    const data = this.http.put(URI, dataUser);

    return data;
  }

  /**
   * Get full name user
   */
  get userFullName(): Observable<string> {
    return this.fullNameSubject$.asObservable();
  }
  /**
   * Get avatar user
   */
  get userAvatar(): Observable<string> {
    return this.avatarUserSubject$.asObservable();
  }
  /**
   * change full name user
   * @param {string} fullName
   */
  changeFullNameUser(fullName: string): void {
    this.fullNameSubject$.next(fullName);
    const currentDataSession = JSON.parse(localStorage.getItem('dataUser') ?? '');
    localStorage.setItem(
      'dataUser',
      JSON.stringify({
        ...currentDataSession,
        full_name: fullName,
      })
    );
  }
  /**
   * change avatar user
   * @param {string} avatar
   */
  changeAvatarUser(avatar: string): void {
    this.avatarUserSubject$.next(avatar);
    localStorage.setItem('imgUser', avatar);
  }
  /**
   * unsubscribe all observables
   */
  unsubscribeObservables(): void {
    this.fullNameSubject$.complete();
    this.avatarUserSubject$.complete();
  }
}
