import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AUROFAST_CONSTANTS } from '../../models/constants';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class CollaboratorService {
  urlApi = environment.url_api;
  infoToken: any;

  httpOptions: any = {
    headers: new HttpHeaders({
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
    }),
  };

  constructor(
    private http: HttpClient,
    private _authService: AuthService
  ) {}

  getCollaborators(): Observable<any> {
    this.infoToken = this._authService.decodeToken().then(value => value);

    const URI = this.urlApi + 'user/company';

    return this.http.get(URI, this.httpOptions).pipe(
      catchError(async err => {
        if (err.status == 401) {
          await this._authService.logout();
        }

        this.handleError(err);
      })
    );
  }

  getCollaboratorsFriends(): Observable<any> {
    this.infoToken = this._authService.decodeToken().then(value => value);

    const URI = this.urlApi + 'user/company/friends';

    return this.http.get(URI, this.httpOptions).pipe(
      catchError(async err => {
        if (err.status == 401) {
          await this._authService.logout();
        }

        this.handleError(err);
      })
    );
  }

  changeStatusUser(id: string, status: string): Observable<any> {
    if (status == 'ACTIVE') {
      status = 'SUSPENDED';
    } else {
      status = 'ACTIVE';
    }
    const data = { status: status };
    const URI: string = this.urlApi + `user/${id}/change-state`;

    return this.http.put(URI, data);
  }

  sendMailActivateUser(id: string): Observable<any> {
    const URI: string =
      this.urlApi + `user/send-mail/user/${id}/template/${AUROFAST_CONSTANTS.AUROFAST_ACTIVATE_ACCOUNT}`;

    return this.http.post(URI, '');
  }

  createCollaboratorsBulk(data: any): Observable<any> {
    const URI: string = this.urlApi + 'user/bulk-user';

    return this.http.post(URI, data);
  }

  private handleError(err: HttpErrorResponse) {
    let errorMessage: string;

    if (err.error instanceof ErrorEvent) {
      //Deprecated. Http requests never emit an ErrorEvent
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }

    throw new Error(errorMessage);
  }
}
