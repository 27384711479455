import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  dataUser = JSON.parse(`${localStorage.getItem('dataUser')}`);
  private urlApi = environment.url_api;

  constructor(
    private http: HttpClient,
    private _authService: AuthService
  ) {}

  async dataForm(): Promise<any> {
    const URI: string = this.urlApi + 'document';
    const data = await this.http.get(URI).toPromise();

    return data;
  }

  saveValue(data: any, tipyFile: any): Observable<any> {
    const URI: string = this.urlApi + `convert/${tipyFile}`;

    return this.http.post(URI, data).pipe(
      catchError((error: any) => {
        if (error.statusCode === 500) {
          return throwError({
            data: null,
            error: true,
            msg: 'An internal server error occurred. Please try again later.',
          });
        } else {
          // For other errors, re-throw the error to propagate it further
          return throwError(error);
        }
      })
    );
  }
}
