import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoadingService {
  loading = new Subject<boolean>();
  loading$: any = this.loading.asObservable() ?? false;

  show() {
    this.loading.next(true);
  }

  hide() {
    this.loading.next(false);
  }
}
