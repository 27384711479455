import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AUROFAST_CONSTANTS } from '../../models/constants';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class RegisterService {
  urlApi = environment.url_api;
  infoToken: any;

  constructor(
    private http: HttpClient,
    private _authService: AuthService
  ) {}

  registerUser(datauser: any): Observable<any> {
    const URI = this.urlApi + `auth/register/${AUROFAST_CONSTANTS.AUROFAST_ACTIVATE_ACCOUNT}`;

    return this.http.post(URI, datauser).pipe(
      catchError(async err => {
        if (err.status == 401) {
          await this._authService.logout();
        }

        this.handleError(err);
      })
    );
  }

  registerBusiness(businessDataForm: any): Observable<any> {
    const URI: string = this.urlApi + 'company';

    return this.http.post(URI, businessDataForm);
  }

  async registerCollaborators(data: any): Promise<Observable<any>> {
    this.infoToken = await this._authService.decodeToken();
    data.company = this.infoToken?.user?.company;

    const URI: string = this.urlApi + `user/template/${AUROFAST_CONSTANTS.AURODOC_COMPANY_ACTIVATE_ACCOUNT}`;

    return this.http.post(URI, data).pipe(
      catchError(async err => {
        if (err.status == 401) {
          await this._authService.logout();
        }

        this.handleError(err);
      })
    );
  }

  private handleError(err: HttpErrorResponse) {
    let errorMessage: string;

    if (err.error instanceof ErrorEvent) {
      //Deprecated. Http requests never emit an ErrorEvent
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }

    throw new Error(errorMessage);
  }
}
