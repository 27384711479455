import { Injectable, NgZone } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { SseService } from '../sse/sse.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  dataUser = JSON.parse(`${localStorage.getItem('dataUser')}`);
  url_api = environment.url_api;

  constructor(
    private _zone: NgZone,
    private _seeService: SseService,
    private http: HttpClient
  ) {}

  async notificationWatchOn(): Promise<any> {
    const URI = this.url_api + 'notifier/watchon/Aurodocs';

    try {
      return await this.http.get(URI).toPromise();
    } catch (error) {
      return error;
    }
  }

  getNotification(): Observable<any> {
    const URI = this.url_api + `notifier/owner/${this.dataUser.user_id}`;

    return this.http.get(URI).pipe(
      retry(3),
      catchError(async err => err)
    );
  }

  async notificationUpdate(id: any): Promise<any> {
    const URI = this.url_api + `notifier/${id._id}`;
    const data = {
      status: 'READ',
    };

    try {
      return await this.http.put(URI, data).toPromise();
    } catch (error) {
      return error;
    }
  }
}
