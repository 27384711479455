export enum TypeGrid {
  TEXT = 'text',
  INPUT = 'input',
  IMAGE = 'image',
}

export enum AlignContent {
  START = 'top',
  CENTER = 'center',
  END = 'bottom',
}

export enum AlignVerticalContent {
  START = 'start',
  CENTER = 'center',
  END = 'end',
}

export enum AlignTextInput {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}
