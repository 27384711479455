export const FORGOT_PASSWORD_FORM_LABELS = {
  EMAIL_ERROR: {
    required: 'Email is required',
    validEmail: 'Please enter a valid email address',
  },
  PASSWORD_ERROR: {
    required: 'Password is required',
    minLength: 'You must enter at least 8 characters',
    passwordDoNotMatch: 'The passwords do not match',
  },
};
