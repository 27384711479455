import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  menu: any;

  async getMenu(): Promise<any> {
    const localData = JSON.parse(`${localStorage.getItem('dataUser')}`);

    this.menu = localData.menu;

    return this.menu;
  }
}
