import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WebApiResponse } from '../../models/types';
import { BaseService } from '../base/base.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MassiveDownloadService extends BaseService {
  private eventSource: EventSource | undefined;

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  //TODO: Update this file service according to new implementation. (Use as reference document.service.ts)

  downloadTemplate(documentId: string): WebApiResponse<any> {
    const URI = `document/create-excel/${documentId}`;

    return this.get(URI);
  }

  async initDownload(typeFile: string, data: any): Promise<any> {
    try {
      const formData: any = new FormData();
      formData.append('name', data?.name);
      formData.append('description', data?.description);
      formData.append('type_dataform', 'MASSIVE');
      formData.append('owner', data?.owner);
      formData.append('file', data?.file);
      formData.append('document', data?.document);

      const URI = `convert/multiple/${typeFile}`;

      return await this.post(URI, formData).toPromise();
    } catch (error) {
      return error;
    }
  }

  downloadBulkDocuments(uuid: string): any {
    // const owner = request.owner;
    // const document = request.document;
    // const URI = `convert/generate-bulk-documents/owner/${owner}/document/${document}`;
    const URI = `convert/generate-bulk-documents/${uuid}`;

    return new Observable<any>(observer => {
      this.eventSource = new EventSource(this.getFullURL(URI));

      this.eventSource.onmessage = event => {
        const data = JSON.parse(event.data);
        observer.next(data);
      };
    });
  }

  unsubscribeFromSSE() {
    if (this.eventSource) {
      this.eventSource.close();
      this.eventSource = undefined;
    }
  }
}
