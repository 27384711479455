import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthLoggedGuard } from '../../core/guards';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
    canActivate: [AuthLoggedGuard],
    data: { title: 'Iniciar sesión' },
  },
  {
    path: 'register',
    loadChildren: () => import('./ui-register/ui-register.module').then(m => m.UiRegisterModule),
    canActivate: [AuthLoggedGuard],
    data: { title: 'Registrarse' },
  },
  {
    path: 'recovery',
    loadChildren: () => import('./reset-password/reset-password.module').then(m => m.ResetPasswordModule),
    data: { title: 'Olvidé mi contraseña' },
  },
  {
    path: 'forgot-password/:token',
    loadChildren: () => import('./forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
    data: { title: 'Recuperar contraseña' },
  },
  {
    path: 'confirm-account/:status',
    loadChildren: () => import('./confirm-account/confirm-account.module').then(m => m.ConfirmAccountModule),
    data: { title: 'Confirmación de cuenta' },
  },
  {
    path: 'active-account/:token',
    loadChildren: () => import('./activate-account/activate-account.module').then(m => m.ActivateAccountModule),
    data: { title: 'Activación de cuenta' },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
