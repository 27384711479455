import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoadingService } from '../../services/loading/loading.service';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  constructor(private loadingService: LoadingService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Muestra el spinner
    this.loadingService.show();

    // Continúa la petición
    return next.handle(req).pipe(
      // Oculta el spinner al finalizar la petición, ya sea con éxito o error
      finalize(() => this.loadingService.hide())
    );
  }
}
