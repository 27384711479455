import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AppResolveService {
  urlApi = environment.url_api;

  constructor(
    private http: HttpClient,
    private router: Router
  ) {}

  resolve(token: string): Observable<any> {
    const uri = this.urlApi + 'auth/activate-account/' + token;

    return this.http.post(uri, {}).pipe(tap(resp => resp));
  }
}
