export const TABLE_COLS = [
  {
    field: 'name',
    header: 'Name',
    style: 'width: 40%',
  },
  {
    field: 'updated_at',
    header: 'Updated At',
    style: 'width: 12.5%',
  },
  {
    field: 'created_at',
    header: 'Created At',
    style: 'width: 12.5%',
  },
  {
    field: 'status_doc',
    header: 'Status',
    style: 'width: 25%',
  },
];

export const TABLE_COLS_COLLABORATORS = [
  {
    field: 'name',
    header: 'Name',
    style: 'width: 45%',
  },
  {
    field: 'state',
    header: 'Status',
    style: 'width: 25%',
  },
];

export const TABLE_COLS_HEADER_AND_FOOTER = [
  {
    field: 'name',
    header: 'Name',
    style: 'width: 45%',
  },
  {
    field: 'status',
    header: 'Status',
    style: 'width: 25%',
  },
];

export const TABLE_COLS_MANAGE_LIST = [
  {
    field: 'name_list',
    header: 'Name list',
    style: 'width: 25%',
  },
  {
    field: 'description',
    header: 'Description',
    style: 'width: 40%',
  },
  {
    field: 'shared_with',
    header: 'Shared With',
    style: 'width: 10%',
  },
  {
    field: 'created_at',
    header: 'Date',
    style: 'width: 15%',
  },
];
