import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../services';

@Injectable({
  providedIn: 'root',
})
export class HeaderFooterService {
  urlApi: string = environment.url_api + 'frame';

  httpOptions: any = {
    headers: new HttpHeaders({
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
    }),
  };

  constructor(
    private http: HttpClient,
    private _authService: AuthService
  ) {}

  create(body: any): Observable<any> {
    const URI = this.urlApi;

    return this.http.post(URI, body, this.httpOptions).pipe(
      catchError(async err => {
        this.handleError;
        if (err.status == 401) {
          this._authService.logout();
        }
      })
    );
  }

  getLayouts(): Observable<any> {
    const owner = JSON.parse(localStorage.getItem('dataUser') || '').user_id;
    const URI = this.urlApi + '/owner/' + owner + '/status/ACTIVE';

    return this.http.get(URI, this.httpOptions).pipe(
      catchError(async err => {
        this.handleError;
        if (err.status == 401) {
          this._authService.logout();
        }
      })
    );
  }

  deleteLayout(id: string): Observable<any> {
    const URI = this.urlApi + '/' + id;

    return this.http.delete(URI, this.httpOptions).pipe(
      catchError(async err => {
        this.handleError;
        if (err.status == 401) {
          this._authService.logout();
        }
      })
    );
  }

  getLayout(id: string): Observable<any> {
    const URI = this.urlApi + '/' + id;

    return this.http.get(URI, this.httpOptions).pipe(
      catchError(async err => {
        this.handleError;
        if (err.status == 401) {
          this._authService.logout();
        }
      })
    );
  }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error ocurred: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }

    return throwError(errorMessage);
  }
}
