import { Injectable } from '@angular/core';
import { Observable, interval } from 'rxjs';
import { map, takeWhile } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TimerService {
  private readonly COUNTDOWN_DURATION: number = 120000; // Duración del temporizador en milisegundos
  private timer$: Observable<string> | undefined;

  constructor() {}

  initializeTimer(): Observable<string> {
    if (!this.timer$) {
      const startTime = new Date().getTime();
      const endTime = startTime + this.COUNTDOWN_DURATION;

      this.timer$ = interval(1000).pipe(
        map(() => {
          const currentTime = new Date().getTime();
          const remainingTime = endTime - currentTime;

          if (remainingTime <= 0) {
            return '00:00';
          }

          const minutes = Math.floor(remainingTime / 60000);
          const seconds = Math.floor((remainingTime % 60000) / 1000);

          const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
          const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

          return `${formattedMinutes}:${formattedSeconds}`;
        }),
        takeWhile(time => time !== '00:00')
      );
    }

    return this.timer$;
  }
}
